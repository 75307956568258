<template>
  <div>
    <Breadcrumbs>
      <router-link to="/home/member-info">
        {{ $t("menu.main.membershipInformation") }}
      </router-link>
      > {{ $route.meta.title }}
    </Breadcrumbs>
    <div class="form-container px-5 pb-15">
      <h1 class="text-heading--c-prm text-center py-6">
        {{ $t("memberInfo.changeContactInformation") }}
      </h1>

      <v-form
        ref="form"
        lazy-validation
        class="px-5 py-7"
      >
        <!-- START Postal-code -->
        <label for="postalCode">{{ $t("common.postalCode") }}</label>
        <PostalCodeInput
          id="postalCode"
          v-model="mainContact.postalCode"
          class="mt-3"
          outlined
        />
        <!-- END Postal-code -->

        <!-- State/Province -->
        <label for="prefecture">{{ $t("common.province") }}</label>
        <v-text-field
          id="prefecture"
          :rules="rules.text"
          v-model="mainContact.prefecture"
          x-large
          solo
          outlined
          flat
          type="text"
          class="mt-2 rounded-lg"
        />
        <!-- -------------- -->

        <!-- City/District-->
        <label for="locality">{{ $t("common.district") }}</label>
        <v-text-field
          id="locality"
          :rules="rules.text"
          v-model="mainContact.locality"
          x-large
          solo
          flat
          outlined
          type="text"
          class="mt-2 rounded-lg"
        />
        <!-- ------------- -->

        <label for="address1">{{ $t("common.street") }}</label>
        <v-text-field
          id="address1"
          :rules="rules.text"
          v-model="mainContact.address1"
          x-large
          solo
          flat
          outlined
          type="text"
          class="mt-2 rounded-lg"
        />

        <!-- Building/Apartment name, etc. -->
        <label for="address2">{{ $t("common.buildingName") }}</label>
        <v-text-field
          id="address2"
          v-model="mainContact.address2"
          x-large
          solo
          flat
          outlined
          type="text"
          class="mt-2 rounded-lg"
        />
        <!-- ----------------------------- -->

        <label for="telNumber">{{ $t("common.phoneNumber") }}</label>
        <v-text-field
          id="telNumber"
          :rules="phoneNumberRules"
          v-model="mainContact.tel"
          @blur="runValidation"
          placeholder="000-123-1234"
          x-large
          solo
          flat
          outlined
          type="text"
          class="mt-2 rounded-lg"
        />

        <label for="mobileNumber">{{ $t("common.cellPhoneNumber") }}</label>
        <v-text-field
          id="mobileNumber"
          :rules="phoneNumberRules"
          v-model="mainContact.mobile"
          @blur="runValidation"
          placeholder="000-123-1234"
          x-large
          solo
          flat
          outlined
          type="text"
          class="mt-2 rounded-lg"
        />

        <label for="email">{{ $t("common.emailAddress") }}</label>
        <v-text-field
          id="email"
          :rules="[$rules.required, $rules.email]"
          v-model="mainContact.email"
          x-large
          solo
          flat
          outlined
          type="email"
          class="mt-2 rounded-lg"
        />
      </v-form>

      <v-btn
        elevation="0"
        x-large
        rounded
        block
        @click="submitForm"
        class="mt-6"
        color="btn-grad--orange"
      >
        <span class="text-heading font-weight-regular w-100">{{ $t("buttons.save") }}</span>
        <IconChevronRightCircle color="white" width="23" height="23" class="mr-2"/>
      </v-btn>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '../../../components/Breadcrumbs'
import IconChevronRightCircle from '../../../components/icons/IconChevronRightCircle'
import {
  checkPhoneNumber,
  checkPostalCode
} from '@/utils/validate'
import PostalCodeInput from '../../../components/Inputs/PostalCodeInput'

export default {
  name: 'MemberInfoContact',
  components: {
    PostalCodeInput,
    Breadcrumbs,
    IconChevronRightCircle
  },
  computed: {
    mainContact () {
      return this.$store.state.memberInfo.mainContact ?? {}
    },
    phoneNumberRules () {
      return [
        v => !v || checkPhoneNumber(v) || this.$t('rules.invalid'),
        v => !!v || !!this.mainContact.tel || !!this.mainContact.mobile || this.$t('rules.isRequired')
      ]
    }
  },
  async mounted () {
    await this.$doLoading(async () => {
      await this.$showGqlError(async () => {
        await this.$store.dispatch('getMainContact', {
          apollo: this.$apollo
        })
      })
    })
  },
  methods: {
    async submitForm () {
      if (this.$refs.form.validate()) {
        await this.$doLoading(async () => {
          await this.$showGqlError(async () => {
            await this.$store.dispatch('updateMyMainContact', {
              apollo: this.$apollo,
              postalCode: this.mainContact.postalCode,
              tel: this.mainContact.tel,
              mobile: this.mainContact.mobile,
              email: this.mainContact.email,
              prefecture: this.mainContact.prefecture,
              locality: this.mainContact.locality,
              address1: this.mainContact.address1,
              address2: this.mainContact.address2
            })
            await this.$router.push('/home/member-info')
          })
        })
      }
    },
    runValidation () {
      this.$refs.form.validate()
    }
  },
  data () {
    return {
      rules: {
        text: [
          v => !!v || this.$t('rules.isRequired'),
          v => v?.trim().length < 70 || this.$t('rules.invalid')
        ],
        postalCode: [
          v => !!v || this.$t('rules.isRequired'),
          v => checkPostalCode(v) || this.$t('rules.invalid')
        ]
      }
    }
  }
}
</script>
